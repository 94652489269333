"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    fi: {
        Fault: {
            userName: 'Ilmoittaja',
            vehicleName: 'Ajoneuvo',
            createdAt: 'Luotu',
            photo: 'Kuva',
            vehicleId: 'Ajoneuvo',
            vehicleIdPlaceholder: 'Valitse ajoneuvo',
            description: 'Kuvaus',
        },
    },
};
