import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { AuthLoadingView } from 'shared-ui/lib/components/auth/AuthLoadingView';
import { authConfig } from 'shared-ui/lib/config/auth.config';

type Props = {
  children: JSX.Element;
};

export const RequireGuest = (props: Props): JSX.Element => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem(authConfig.tokenStorageKey);

    if (token) {
      navigate('/');
    }
    setIsLoading(false);
  }, [navigate]);

  if (isLoading) return <AuthLoadingView />;

  return props.children;
};
