export const uploadConfig = {
  api: `${process.env.REACT_APP_API_URL}/upload`,
};

export type ImageType =
  | 'vehicle-image'
  | 'service-event-image'
  | 'business-image'
  | 'fault-image'
  | 'partner-image';
