"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthErrorView = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_i18next_1 = require("react-i18next");
var react_router_dom_1 = require("react-router-dom");
var AuthLayout_1 = require("./AuthLayout");
var AuthLogoTitle_1 = require("./AuthLogoTitle");
var AuthErrorView = function (_a) {
    var errorReason = _a.errorReason, loginRoute = _a.loginRoute;
    var t = (0, react_i18next_1.useTranslation)('AuthErrorView').t;
    return ((0, jsx_runtime_1.jsx)(AuthLayout_1.AuthLayout, { children: (0, jsx_runtime_1.jsx)("div", { className: "mt-8 sm:mx-auto sm:w-full sm:max-w-md", children: (0, jsx_runtime_1.jsxs)("div", { className: "bg-white py-8 px-4 shadow rounded-lg sm:px-10", children: [(0, jsx_runtime_1.jsx)(AuthLogoTitle_1.AuthLogoTitle, { title: t('wentWrong'), className: "text-primary" }), (0, jsx_runtime_1.jsx)("div", { className: "mt-6 text-center text-sm text-gray-color", children: errorReason }), (0, jsx_runtime_1.jsx)(react_router_dom_1.Link, { to: loginRoute, className: "mt-6 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary", children: t('backLink') })] }) }) }));
};
exports.AuthErrorView = AuthErrorView;
