import { createForm } from 'shared-ui/lib/releox-engine/form/FormGenerator';
import { FormInput } from 'shared-ui/lib/releox-engine/form/FormInput';
import { userInputs } from '../../inputs/user.inputs';

const form: FormInput[] = [userInputs.email, userInputs.password];

export const loginForm = createForm(form);

export type LoginFormBody = {
  email: string;
  password: string;
};
