"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    fi: {
        Reminder: {
            id: '#',
            date: 'Päivämäärä',
            reminder: 'Muistutus',
            vehicle: 'Ajoneuvo',
            VIN: 'Rekisterinumero',
            title: 'Otsikko',
            notificationDaysBefore: 'Muistutus päivää ennen',
            isNotificationable: 'Muistutus',
        },
    },
};
