"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInputClasses = void 0;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
var useInputClasses = function (meta) {
    var inputClasses = 'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm';
    if (meta.error && meta.touched) {
        inputClasses += ' border-red-color';
    }
    return inputClasses;
};
exports.useInputClasses = useInputClasses;
