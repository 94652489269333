"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    fi: {
        VehicleType: {
            MOTOR_VEHICLE: 'Auto',
            MOTOR_CARAVAN: 'Matkailuauto',
            MOTORCYCLE: 'Moottoripyörä',
            BICYCLE: 'Polkupyörä',
            LIGHT_ELECTRIC_VEHICLE: 'Kevyt sähköajoneuvo',
            VEHICLE_OF_HISTORIC_INTEREST: 'Museoajoneuvo',
            TRACTOR: 'Traktori',
            PUBLIC_WORKS_VEHICLE: 'Moottorityökone',
            OFF_ROAD_VEHICLE: 'Maastoajoneuvo',
            WORK_MACHINE_MOUNTED_ON_A_VEHICLE_CHASSIS: 'Auton alustalle rakennettu työkone',
            TRAILER: 'Perävaunu',
            ACCESSIBLE_VEHICLE: 'Esteetön ajoneuvo',
            MOPED: 'Mopo',
            OTHER: 'Muu',
        },
    },
};
