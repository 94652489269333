"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    fi: {
        General: {
            updated: 'Tiedot päivitetty',
            link: 'Linkki',
            save: 'Tallenna',
            back: 'Takaisin',
            delete: 'Poista',
        },
    },
};
