"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    fi: {
        User: {
            name: 'Nimi',
            email: 'Sähköposti',
            businessName: 'Yrityksen nimi',
            password: 'Salasana',
            isEmployee: 'Työntekijä',
            partnerName: 'Yrityksen nimi',
            terms: '* Tutustu Ajoneuvoni.fi ehtoihin',
            isAccepted: 'Olen lukenut ja hyväksyn ehdot',
            admin: 'Järjestelmänvalvoja',
            employee: 'Työntekijä',
            type: 'Tyyppi',
            isOwner: 'Omistaja',
            partner: 'Partner',
            business: 'Yritys',
            id: 'ID',
            stripeCustomerId: 'Stripe ID',
            businessId: 'Yrityksen ID',
            userId: 'Käyttäjän ID',
            partnerNameFilter: 'Partnerin nimi',
            businessNameFilter: 'Yrityksen nimi',
        },
    },
};
