import Axios, { AxiosRequestConfig } from 'axios';
import { authConfig } from '../config/auth.config';
import { ImageType, uploadConfig } from '../config/upload.config';

export const uploadFileToServer = async (file: File, imageType: ImageType, resourceId: string) => {
  const token = localStorage.getItem(authConfig.tokenStorageKey);

  if (!token) throw new Error('[uploadFile] Token is undefined');

  const opts: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const body = new FormData();
  body.append('file', file);
  body.append('relationId', resourceId);
  body.append('fileType', imageType);

  const { data } = await Axios.post(uploadConfig.api, body, opts);
  return data;
};
