"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    fi: {
        AuthErrorView: {
            wentWrong: 'Jotain meni pieleen..',
            backLink: 'Takaisin kirjautumissivulle',
        },
    },
};
