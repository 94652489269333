import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AuthButton } from 'shared-ui/lib/components/auth/AuthButton';
import { AuthCard } from 'shared-ui/lib/components/auth/AuthCard';
import { AuthLayout } from 'shared-ui/lib/components/auth/AuthLayout';
import { AuthLogoTitle } from 'shared-ui/lib/components/auth/AuthLogoTitle';
import { FormGen } from 'shared-ui/lib/components/generic-form-scene/FormGen';
import { useHandleSubmit } from 'shared-ui/lib/hooks/apollo/use-handle-submit';
import { useOnCompleteRedirect } from 'shared-ui/lib/hooks/apollo/use-on-complete-redirect';
import { routesConfig } from '../../../config/routes.config';
import { forgotPasswordForm } from '../../../form/forgot-password/forgot-password.form';
import { useForgotPasswordMutation } from '../../../generated/graphql';

export const ForgotScene = (): JSX.Element => {
  const { t } = useTranslation('ForgotScene');

  const onComplete = useOnCompleteRedirect(routesConfig.AUTH.FORGOT_SUCCESS);

  const [forgot, { loading }] = useForgotPasswordMutation(onComplete);

  const handleSubmit = useHandleSubmit(forgot);

  return (
    <AuthLayout>
      <AuthLogoTitle title={t('resetPassword')} />
      <AuthCard>
        <FormGen
          onSubmit={handleSubmit}
          form={forgotPasswordForm}
          wrapperClassNames="space-y-4"
          HeaderElement={
            <p className="text-sm font-medium text-gray-700 mb-4 text-center">
              {t('writeYourEmail')}
            </p>
          }
          FooterElement={
            <>
              <AuthButton type="submit" label={t('reset')} loading={loading} />
              <div className="text-sm">
                <p className="mt-6 text-center text-sm text-gray-color">
                  <Link
                    to={routesConfig.AUTH.LOGIN}
                    className="font-medium text-primary hover:text-secondary"
                  >
                    {t('backLink')}
                  </Link>
                </p>
              </div>
            </>
          }
        />
      </AuthCard>
    </AuthLayout>
  );
};
