export default {
  fi: {
    ForgotScene: {
      resetPassword: 'Nollaa salasana',
      writeYourEmail:
        'Kirjoita sähköpostiosoitteesi niin lähetämme sinulle linkin, jolla voit vaihtaa salasanasi.',
      reset: 'Nollaa',
      backLink: 'Takaisin kirjautumissivulle',
    },
  },
};
