"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    fi: {
        Business: {
            name: 'Nimi',
            picture: 'Kuva',
        },
    },
};
