import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { AuthLoadingView } from 'shared-ui/lib/components/auth/AuthLoadingView';
import logout from 'shared-ui/lib/utils/logout';
import { routesConfig } from '../../../config/routes.config';

export const LogoutScene = (): JSX.Element => {
  const navigate = useNavigate();
  useEffect(() => {
    logout();
    navigate(routesConfig.AUTH.LOGIN);
  }, [navigate]);

  return <AuthLoadingView />;
};
