"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeneratableCheckboxInput = exports.CheckboxInput = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var formik_1 = require("formik");
var Label_1 = require("./Label");
var CheckboxInput = function (props) {
    var _a = (0, formik_1.useField)(props), field = _a[0], meta = _a[1];
    var name = props.name, label = props.label;
    var id = "".concat(name, "-input");
    return ((0, jsx_runtime_1.jsx)("fieldset", { className: "space-y-5", children: (0, jsx_runtime_1.jsxs)("div", { className: "relative flex items-start", children: [(0, jsx_runtime_1.jsx)("div", { className: "flex items-center h-5", children: (0, jsx_runtime_1.jsx)("input", __assign({}, field, { id: id, checked: field.value, type: "checkbox", className: "focus:ring-secondary h-4 w-4 text-secondary border-gray-300 rounded" })) }), (0, jsx_runtime_1.jsx)("div", { className: "ml-3 text-sm", children: (0, jsx_runtime_1.jsx)(Label_1.Label, { htmlFor: id, meta: meta, children: label }) })] }) }));
};
exports.CheckboxInput = CheckboxInput;
exports.GeneratableCheckboxInput = {
    Element: exports.CheckboxInput,
    type: 'checkbox',
};
