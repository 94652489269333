import { createForm } from 'shared-ui/lib/releox-engine/form/FormGenerator';
import { FormInput } from 'shared-ui/lib/releox-engine/form/FormInput';
import { userInputs } from '../../inputs/user.inputs';

const form: FormInput[] = [userInputs.password];

export const resetPasswordForm = createForm(form);

export type ResetPasswordFormBody = {
  password: string;
};
