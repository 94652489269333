"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeneratableFileInput = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_i18next_1 = require("react-i18next");
var use_file_upload_input_1 = require("./use-file-upload-input");
var FileInput = function (props) {
    var t = (0, react_i18next_1.useTranslation)('FileInput').t;
    var _a = (0, use_file_upload_input_1.UseFileUploadInput)(props.name), getInputProps = _a.getInputProps, getRootProps = _a.getRootProps, preview = _a.preview;
    return ((0, jsx_runtime_1.jsxs)("div", { className: "FileInput", children: [(0, jsx_runtime_1.jsx)("label", { htmlFor: "photo", className: "block text-sm font-medium text-gray-700", children: t(props.label) }), (0, jsx_runtime_1.jsx)("div", { className: "mt-1 sm:mt-0 sm:col-span-2", children: (0, jsx_runtime_1.jsxs)("div", { className: "flex items-center", children: [(0, jsx_runtime_1.jsx)("span", { className: "h-12 w-12 rounded-full overflow-hidden bg-gray-100", children: (0, jsx_runtime_1.jsx)("img", { className: "h-full w-full text-gray-300", src: preview, alt: "" }) }), (0, jsx_runtime_1.jsxs)("div", __assign({}, getRootProps(), { children: [(0, jsx_runtime_1.jsx)("input", __assign({ type: "file" }, getInputProps(), { multiple: false })), (0, jsx_runtime_1.jsx)("button", { type: "button", className: "ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary", children: t('changeImage') })] }))] }) })] }));
};
exports.GeneratableFileInput = {
    Element: FileInput,
    type: 'file',
};
