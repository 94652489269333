"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormInputs = void 0;
var ArrayInput_1 = require("../react/form-inputs/ArrayInput");
var Checkbox_1 = require("../react/form-inputs/Checkbox");
var FileInput_1 = require("../react/form-inputs/file-input/FileInput");
var ReleoxFormElement_1 = require("../react/form-inputs/ReleoxFormElement");
var SelectInput_1 = require("../react/form-inputs/SelectInput");
var TextareaInput_1 = require("../react/form-inputs/TextareaInput");
var TextInput_1 = require("../react/form-inputs/TextInput");
exports.FormInputs = [
    TextInput_1.GeneratableTextInput,
    FileInput_1.GeneratableFileInput,
    Checkbox_1.GeneratableCheckboxInput,
    SelectInput_1.GeneratableSelectInput,
    TextareaInput_1.GeneratableTextareaInput,
    ArrayInput_1.GeneratableArrayInput,
    ReleoxFormElement_1.GeneratableReleoxFormElement,
];
