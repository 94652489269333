"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    fi: {
        ServiceEventType: {
            OIL: 'Öljyhuolto',
            AIR_FILTER: 'Ilmansuodatin',
            FUEL_FILTER: 'Polttoainesuodatin',
            FRONT_BREAK: 'Etujarrut',
            REAR_BREAK: 'Takajarrut',
            SUMMER_TIRE: 'Kesärenkaat',
            WINTER_TIRE: 'Talvirenkaat',
            TIMING_BELT: 'Jakopää',
            LIGHTS: 'Valot',
            OTHER: 'Muu',
            EXTINGUISHER: 'Sammutin',
            ALCOLOCK: 'Alkolukko',
            SPEED_LIMITER: 'Nopeudenrajoitin',
            TACHOGRAPH: 'Piirturi',
            INSPECTION: 'Katsastus',
            TAXIMETER: 'Taksamittari',
            GAS_EQUIPMENTS: 'Kaasulaitteet',
        },
    },
};
