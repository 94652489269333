"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SidebarCompany = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var Image_1 = require("./Image");
var SidebarCompany = function (props) {
    var userName = props.userName, companyName = props.companyName, photo = props.photo;
    return ((0, jsx_runtime_1.jsx)("div", { className: "flex-shrink-0 flex p-4", children: (0, jsx_runtime_1.jsxs)("div", { className: "flex-shrink-0 w-full group block", children: [(0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)("p", { className: "text-lg md:text-sm font-medium text-primary text-center", children: userName }), (0, jsx_runtime_1.jsx)("p", { className: "text-md md:text-xs font-bold text-secondary -mt-1 md:mt-0 text-center", children: companyName })] }), (0, jsx_runtime_1.jsx)("div", { className: "items-center text-center mt-2", children: (0, jsx_runtime_1.jsx)(Image_1.Image, { w: 300, className: "inline-block h-12 md:h-14", src: photo, placeholderType: "thumbnail" }) })] }) }));
};
exports.SidebarCompany = SidebarCompany;
