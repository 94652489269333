"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    fi: {
        Vehicle: {
            name: 'Nimi / tunniste',
            VIN: 'Ajoneuvon valmistenumero',
            registerNumber: 'Rekisterinumero',
            mileage: 'Kilometrit',
            modelYear: 'Vuosimalli',
            commissioning: 'Käyttöönotto',
            warranty: 'Takuu',
            runningHours: 'Käyttötunnit',
            model: 'Malli',
            brand: 'Merkki',
            token: 'Hakutunniste',
            isArchived: 'Arkistoitu',
            type: 'Tyyppi',
            description: 'Kuvaus',
            choose: 'Valitse',
            picture: 'Kuva',
            licenseStatus: 'Lisenssin tila',
            LicenseStats: 'Lisenssin tiedot',
            id: 'ID',
            businessName: 'Yrityksen nimi',
            userName: 'Käyttäjän nimi',
        },
    },
};
