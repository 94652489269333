"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeneratableTextInput = exports.TextInput = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var formik_1 = require("formik");
var react_i18next_1 = require("react-i18next");
var InputWrapper_1 = require("./InputWrapper");
var Label_1 = require("./Label");
var use_input_classes_1 = require("./use-input-classes");
var TextInput = function (props) {
    var t = (0, react_i18next_1.useTranslation)().t;
    var _a = (0, formik_1.useField)(props), field = _a[0], meta = _a[1];
    var name = props.name, label = props.label;
    var id = "".concat(name, "-input");
    var inputClasses = (0, use_input_classes_1.useInputClasses)(meta);
    return ((0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)(Label_1.Label, { htmlFor: id, meta: meta, children: label }), (0, jsx_runtime_1.jsxs)(InputWrapper_1.InputWrapper, { children: [(0, jsx_runtime_1.jsx)("input", __assign({}, props, field, { placeholder: t(label), className: inputClasses })), (0, jsx_runtime_1.jsx)(formik_1.ErrorMessage, { component: "span", className: "text-red-color text-sm", name: field.name })] })] }));
};
exports.TextInput = TextInput;
exports.GeneratableTextInput = {
    Element: exports.TextInput,
    type: 'text',
};
