"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Label = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_i18next_1 = require("react-i18next");
var Label = function (_a) {
    var htmlFor = _a.htmlFor, children = _a.children, meta = _a.meta;
    var t = (0, react_i18next_1.useTranslation)().t;
    var labelClasses = "block text-sm font-medium text-gray-700";
    if (meta.error && meta.touched) {
        labelClasses += " text-red-color";
    }
    return ((0, jsx_runtime_1.jsx)("label", { htmlFor: htmlFor, className: labelClasses, children: t(children) }));
};
exports.Label = Label;
