"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    fi: {
        Pagination: {
            previous: 'Edellinen',
            next: 'Seuraava',
            page: 'Sivu',
            result: '',
        },
    },
};
