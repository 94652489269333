import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AuthCard } from 'shared-ui/lib/components/auth/AuthCard';
import { AuthLayout } from 'shared-ui/lib/components/auth/AuthLayout';
import { AuthLogoTitle } from 'shared-ui/lib/components/auth/AuthLogoTitle';
import { routesConfig } from '../../../config/routes.config';

export const ResetSuccessScene = () => {
  const { t } = useTranslation('ResetSuccessScene');
  return (
    <AuthLayout>
      <AuthCard>
        <AuthLogoTitle title={t('passwordUpdated')} className="text-primary" />
        <Link
          to={routesConfig.AUTH.LOGIN}
          className="mt-8 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
        >
          {t('backLink')}
        </Link>
      </AuthCard>
    </AuthLayout>
  );
};
