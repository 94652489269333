"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeneratableSelectInput = exports.SelectInput = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var formik_1 = require("formik");
var react_i18next_1 = require("react-i18next");
var InputWrapper_1 = require("./InputWrapper");
var Label_1 = require("./Label");
var use_input_classes_1 = require("./use-input-classes");
var SelectInput = function (props) {
    var _a = (0, react_i18next_1.useTranslation)(), t = _a.t, i18n = _a.i18n;
    var _b = (0, formik_1.useField)(props), field = _b[0], meta = _b[1];
    var name = props.name, label = props.label;
    var id = "".concat(name, "-input");
    var inputClasses = (0, use_input_classes_1.useInputClasses)(meta);
    return ((0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)(Label_1.Label, { htmlFor: id, meta: meta, children: label }), (0, jsx_runtime_1.jsxs)(InputWrapper_1.InputWrapper, { children: [(0, jsx_runtime_1.jsxs)("select", __assign({}, props, field, { className: inputClasses, children: [props.placeholder ? (0, jsx_runtime_1.jsx)("option", { value: "", children: t(props.placeholder) }) : undefined, props.options.map(function (opt) { return ((0, jsx_runtime_1.jsx)("option", { value: opt.value, children: i18n.exists(opt.label) ? t(opt.label) : opt.label }, opt.value)); })] })), (0, jsx_runtime_1.jsx)(formik_1.ErrorMessage, { component: "span", className: "text-red-color text-sm", name: field.name })] })] }));
};
exports.SelectInput = SelectInput;
exports.GeneratableSelectInput = {
    Element: exports.SelectInput,
    type: 'select',
};
