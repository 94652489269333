"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthButton = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var AuthButton = function (props) {
    var type = props.type, label = props.label, loading = props.loading;
    var text = label;
    if (loading)
        text = 'Lataa...';
    return ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)("button", { type: type, className: "w-full flex justify-center py-2 px-4 border transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary disabled:bg-gray-300", disabled: loading, children: text }) }));
};
exports.AuthButton = AuthButton;
