"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    fi: {
        ServiceEvent: {
            mileage: 'Kilometrit',
            runningHours: 'Käyttötunnit',
            chooseType: 'Valitse huoltotyyppi',
            type: 'Huoltotyyppi',
            oil: 'Öljynvaihto',
            other: 'Muu',
            name: 'Huollon otsikko',
            description: 'Kuvaus',
            date: 'Päivämäärä',
            picture: 'Kuva',
            maintenance: 'Huolto',
        },
    },
};
