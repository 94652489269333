"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertFileToBase64 = void 0;
var convertFileToBase64 = function (file) {
    return new Promise(function (resolve, reject) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            if (typeof reader.result === 'string') {
                resolve(reader.result);
            }
            else {
                reject();
            }
        };
        reader.onerror = function () {
            reject();
        };
    });
};
exports.convertFileToBase64 = convertFileToBase64;
