"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenericFormScene = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var immutable_1 = require("immutable");
var react_1 = require("react");
var react_i18next_1 = require("react-i18next");
var AuthLoadingView_1 = require("../auth/AuthLoadingView");
var BackButton_1 = require("./BackButton");
var DeleteButton_1 = require("./DeleteButton");
var FormGen_1 = require("./FormGen");
var SubmitButton_1 = require("./SubmitButton");
/**
 *  reformat some value that they fit to formik initial values
 *  like reformat date and remove undefined and null values.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
var format = function (data) {
    var values = (0, immutable_1.Map)(data);
    Object.keys(values.toObject()).forEach(function (key) {
        var value = [null, undefined].includes(data[key]) ? '' : data[key];
        values = values.set(key, value);
        // Refactor date format from YYYY-MM-DDTmm:ss to YYYY-MM-DD what is requirements of html date input
        var isDateFormat = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{3}Z$/;
        if (typeof data[key] === 'string' && isDateFormat.test(data[key])) {
            values = values.set(key, data[key].substring(0, 10));
        }
    });
    // Remove graphql ___typename
    values = values.delete('__typename');
    return values.toObject();
};
var GenericFormScene = function (props) {
    var _a, _b, _c;
    var t = (0, react_i18next_1.useTranslation)(props.tNS || 'General').t;
    var form = props.form, onSubmit = props.onSubmit, isSubmitLoading = props.isSubmitLoading, title = props.title, backLink = props.backLink;
    // Handle on submit event
    var submitMiddleware = (0, react_1.useCallback)(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function (baseValues) {
        var validatedValues = form.meta.validateSchema.validateSync(baseValues);
        onSubmit(validatedValues);
    }, [form, onSubmit]);
    if (props.type === 'edit' && ((_a = props.query) === null || _a === void 0 ? void 0 : _a.loading))
        return (0, jsx_runtime_1.jsx)(AuthLoadingView_1.AuthLoadingView, {});
    var key = undefined;
    if (props.type === 'edit')
        key = JSON.stringify((_b = props.query) === null || _b === void 0 ? void 0 : _b.data);
    var Header = (0, jsx_runtime_1.jsx)("h2", { className: "text-lg leading-6 font-bold text-primary", children: title });
    var Buttons = ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(SubmitButton_1.SubmitButton, { text: t('save'), loading: isSubmitLoading }), backLink ? (0, jsx_runtime_1.jsx)(BackButton_1.BackButton, { onClick: props.blocker, to: backLink }) : null, props.type === 'edit' && props.onDelete ? ((0, jsx_runtime_1.jsx)(DeleteButton_1.DeleteButton, { onClick: props.onDelete, loading: isSubmitLoading })) : undefined, (0, jsx_runtime_1.jsx)("div", { className: "clear-both" })] }));
    return ((0, jsx_runtime_1.jsx)("div", { className: "mx-auto w-full max-w-3xl sm:px-2", children: (0, jsx_runtime_1.jsx)("div", { className: "bg-white py-8 px-6 sm:px-10 shadow rounded-lg", children: (0, jsx_runtime_1.jsx)(FormGen_1.FormGen, { wrapperClassNames: "space-y-4", HeaderElement: Header, FooterElement: Buttons, onSubmit: submitMiddleware, form: form, initialValues: props.type === 'edit' && ((_c = props.query) === null || _c === void 0 ? void 0 : _c.data) && props.queryDataPath
                    ? format(props.query.data[props.queryDataPath])
                    : {} }) }) }, key));
};
exports.GenericFormScene = GenericFormScene;
