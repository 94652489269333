"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthLoadingView = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_i18next_1 = require("react-i18next");
var AuthLoadingView = function () {
    var t = (0, react_i18next_1.useTranslation)('AuthLoadingView').t;
    return ((0, jsx_runtime_1.jsx)("div", { className: "min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8 items-center", children: (0, jsx_runtime_1.jsxs)("div", { className: "mt-8 sm:mx-auto sm:w-full sm:max-w-md", children: [(0, jsx_runtime_1.jsx)("div", { className: "flex items-center justify-center ", children: (0, jsx_runtime_1.jsx)("div", { className: "w-16 h-16 border-b-2 border-secondary rounded-full animate-spin" }) }), (0, jsx_runtime_1.jsx)("p", { className: "text-center mt-12 text-sm text-secondary", children: t('loading') })] }) }));
};
exports.AuthLoadingView = AuthLoadingView;
