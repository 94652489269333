import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { ImageType } from '../config/upload.config';
import { uploadFileToServer } from '../utils/upload-file-to-server';

interface Options {
  type: ImageType;
}

export const useUploadFile = ({ type }: Options) => {
  const [isImageLoading, setIsLoading] = useState(false);

  const uploadFile = useCallback(
    async (photo: null | string | File, id: string): Promise<boolean> => {
      if (photo instanceof File) {
        setIsLoading(true);
        try {
          await uploadFileToServer(photo, type, id);
          setIsLoading(false);
          return true;
        } catch (e) {
          if (e instanceof Error) toast.error(e.message);
          setIsLoading(false);
          return false;
        }
      }
      return true;
    },
    [setIsLoading, type]
  );

  return {
    isImageLoading,
    uploadFile,
  };
};
