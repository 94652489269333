"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeneratableReleoxFormElement = exports.ReleoxFormElement = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var ReleoxFormElement = function (props) {
    return (0, jsx_runtime_1.jsx)(props.Element, {});
};
exports.ReleoxFormElement = ReleoxFormElement;
exports.GeneratableReleoxFormElement = {
    type: 'element',
    Element: exports.ReleoxFormElement,
};
