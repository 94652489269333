"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Button = exports.buttonClassNames = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_i18next_1 = require("react-i18next");
exports.buttonClassNames = '';
var Button = function (props) {
    var t = (0, react_i18next_1.useTranslation)('Button').t;
    var type = props.type, children = props.children, loading = props.loading, className = props.className, onClick = props.onClick, disabled = props.disabled;
    var text = children;
    if (loading)
        text = t('loading');
    return ((0, jsx_runtime_1.jsx)("button", { type: type, onClick: onClick, className: "inline-flex justify-center py-2 px-4 border transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary disabled:bg-gray-300 ".concat(className), disabled: disabled || loading, children: text }));
};
exports.Button = Button;
