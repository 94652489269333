"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    fi: {
        ArrayInputLayoutTranslation: {
            add: 'Lisää',
            deleteServiceEvent: 'Poista huolto',
        },
    },
};
